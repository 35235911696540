<template>
    <b-container fluid>
        <div v-if="orderStatus === ''">
            <h2>Register</h2>
            <p class="text-muted">Fields marked with an * are required.</p>
            <validation-observer ref="registerForm" #default="{ invalid }">
                <b-form class="auth-login-form mt-2" @submit.prevent="register">
                    <b-row>
                        <b-col cols="12" lg="3">
                            <b-form-group label="Title *" label-for="register-title">
                                <validation-provider #default="{ errors }" name="Title" vid="title" rules="required">
                                    <b-form-select id="register-title" v-model="booking.title" label="title" :options="[
                                        { value: null, text: '' },
                                        { value: 'Mx', text: 'Mx' },
                                        { value: 'Ms', text: 'Ms' },
                                        { value: 'Mr', text: 'Mr' },
                                        { value: 'Dr', text: 'Dr' },
                                        { value: 'Prof', text: 'Prof' },
                                    ]" name="register-title" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group label="First Name *" label-for="register-firstname">
                                <validation-provider #default="{ errors }" name="First Name" vid="firstname"
                                    rules="required">
                                    <b-form-input id="register-firstname" v-model="booking.firstname"
                                        :state="errors.length > 0 ? false : null" name="register-firstname"
                                        placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group label="Middle Name" label-for="register-middlename">
                                <validation-provider #default="{ errors }" name="Middle Name" vid="middlename" rules="">
                                    <b-form-input id="register-middlename" v-model="booking.middlename"
                                        :state="errors.length > 0 ? false : null" name="register-middlename"
                                        placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group label="Last Name *" label-for="register-lastname">
                                <validation-provider #default="{ errors }" name="Last Name" vid="lastname" rules="required">
                                    <b-form-input id="register-lastname" v-model="booking.lastname"
                                        :state="errors.length > 0 ? false : null" name="register-lastname" placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" lg="6">
                            <!-- email -->
                            <b-form-group label="Email *" label-for="register-email">
                                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                                    <b-form-input id="register-email" v-model="booking.email"
                                        :state="errors.length > 0 ? false : null" name="register-email"
                                        placeholder="your.name@example.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Mobile" label-for="register-mobile">
                                <validation-provider #default="{ errors }" name="Mobile" vid="tel" rules="">
                                    <b-form-input id="register-mobile" v-model="booking.mobile"
                                        :state="errors.length > 0 ? false : null" name="register-mobile" placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Organization" label-for="register-organization">
                                <validation-provider #default="{ errors }" name="Organization" vid="organization" rules="">
                                    <b-form-input id="register-organization" v-model="booking.organization"
                                        :state="errors.length > 0 ? false : null" name="register-organization"
                                        placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Job Title" label-for="register-designation">
                                <validation-provider #default="{ errors }" name="Job Title" vid="" rules="">
                                    <b-form-input id="register-designation" v-model="booking.designation"
                                        :state="errors.length > 0 ? false : null" name="register-designation"
                                        placeholder="" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-button type="submit" variant="primary" :disabled="invalid" class="float-right">
                        <span v-if="eventMode === 'login'">Check In</span><span v-else>Register</span>
                    </b-button>
                    <b-button type="button" variant="outline-secondary" class="mr-1 float-right" @click="hideRegisterModal">
                        Cancel
                    </b-button>
                </b-form>
            </validation-observer>
        </div>
        <div v-if="orderStatus === 'complete'" id="modal-thankyou" centered :title="event.title" size="lg" ok-title="Close" ok-variant="primary" ok-only>
            <h4>Thank you registering for the {{ event.title }}.</h4>
            <br />
            <h5 v-if="event.meta && event.meta.website">For more information about the {{ event.title }}, please visit <a
                :href="event.meta.website" target="_blank">{{ event.meta.website }}</a></h5>
        </div>

    </b-container>
</template>


<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from '@/store/api/api';

export default {
    name: 'RegistrationForm',
    components: {
        BContainer,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BForm,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            booking: {
                title: '',
                firstname: '',
                middlename: '',
                lastname: '',
                email: '',
                mobile: '',
                organization: '',
                designation: ''
            },
            orderStatus: '',
            // validation rules
            required,
            email,
        };
    },
    computed: {
        event() {
            return this.$store.state.event.event;
        },
    },
    // Move the methods related to the registration form here
    methods: {
        hideRegisterModal() {
            this.$emit('hideRegisterModal');
        },
        async register() {
            // console.log('register', this.booking)
            let location = {}
            try {
                // const response = await api.get('https://geolocation-db.com/json/');
                // location = response.data;
                // // console.log('location', location);
                // if (location.country_code) {
                //     this.booking.country = location.country_code
                // }
                const response = await api.get('https://api.hummz.net/v2/countries/ip2location');
                location = response.data;
                if (location.countryCode) {
                 this.booking.country = location.countryCode
                }
            } catch (error) {
                // report error
                console.error('Location error', error);
            }
            const bookingData = {
                data: this.booking,
                ticket: {
                    type: "delegate",
                    name: "Delegate",
                },
                meta: {
                    urlParams: "",
                    location: location,
                    metadata: {
                        timezone: this.$date.tz.guess(),
                        offset: new Date().getTimezoneOffset(),
                        referrer: document.referrer,
                        userAgent: navigator.userAgent,
                        pathName: window.location.href || "https://www.hummz.events",
                        onLine: navigator.onLine,
                    },
                },
            };
            // console.log('bookingData', bookingData)
            // create a new registration
            try {
                const bookingResponse = await api.post(`/events/register/${this.eventSlug}`, bookingData)
                // console.log('bookingResponse', bookingResponse)
                if (bookingResponse && !bookingResponse.data.error) {
                    this.registrationStatus = 'success'
                    this.registrationResponse = bookingResponse.data
                    this.hideRegisterModal()
                    if (this.eventMode === 'login') {
                        this.username = this.booking.email
                        this.login()
                    } else {
                        this.orderStatus = 'complete'
                    }
                } else {
                    if (bookingResponse.data.error) {
                        this.registrationStatus = 'error'
                        this.registrationResponse = bookingResponse.data.error
                    }
                }
            } catch (error) {
                console.error('Registration error', error)
                this.registrationStatus = 'error'
                this.registrationResponse = error.message
            }
        },
    },
    props: {
        eventMode: {
            type: String,
            required: true,
        },
        eventSlug: {
            type: String,
            required: true,
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>