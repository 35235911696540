var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(_vm.orderStatus === '')?_c('div',[_c('h2',[_vm._v("Register")]),_c('p',{staticClass:"text-muted"},[_vm._v("Fields marked with an * are required.")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Title *","label-for":"register-title"}},[_c('validation-provider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-title","label":"title","options":[
                                    { value: null, text: '' },
                                    { value: 'Mx', text: 'Mx' },
                                    { value: 'Ms', text: 'Ms' },
                                    { value: 'Mr', text: 'Mr' },
                                    { value: 'Dr', text: 'Dr' },
                                    { value: 'Prof', text: 'Prof' } ],"name":"register-title"},model:{value:(_vm.booking.title),callback:function ($$v) {_vm.$set(_vm.booking, "title", $$v)},expression:"booking.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"First Name *","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","state":errors.length > 0 ? false : null,"name":"register-firstname","placeholder":""},model:{value:(_vm.booking.firstname),callback:function ($$v) {_vm.$set(_vm.booking, "firstname", $$v)},expression:"booking.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Middle Name","label-for":"register-middlename"}},[_c('validation-provider',{attrs:{"name":"Middle Name","vid":"middlename","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-middlename","state":errors.length > 0 ? false : null,"name":"register-middlename","placeholder":""},model:{value:(_vm.booking.middlename),callback:function ($$v) {_vm.$set(_vm.booking, "middlename", $$v)},expression:"booking.middlename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Last Name *","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","state":errors.length > 0 ? false : null,"name":"register-lastname","placeholder":""},model:{value:(_vm.booking.lastname),callback:function ($$v) {_vm.$set(_vm.booking, "lastname", $$v)},expression:"booking.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"your.name@example.com"},model:{value:(_vm.booking.email),callback:function ($$v) {_vm.$set(_vm.booking, "email", $$v)},expression:"booking.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"tel","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-mobile","state":errors.length > 0 ? false : null,"name":"register-mobile","placeholder":""},model:{value:(_vm.booking.mobile),callback:function ($$v) {_vm.$set(_vm.booking, "mobile", $$v)},expression:"booking.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Organization","label-for":"register-organization"}},[_c('validation-provider',{attrs:{"name":"Organization","vid":"organization","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-organization","state":errors.length > 0 ? false : null,"name":"register-organization","placeholder":""},model:{value:(_vm.booking.organization),callback:function ($$v) {_vm.$set(_vm.booking, "organization", $$v)},expression:"booking.organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Job Title","label-for":"register-designation"}},[_c('validation-provider',{attrs:{"name":"Job Title","vid":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-designation","state":errors.length > 0 ? false : null,"name":"register-designation","placeholder":""},model:{value:(_vm.booking.designation),callback:function ($$v) {_vm.$set(_vm.booking, "designation", $$v)},expression:"booking.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[(_vm.eventMode === 'login')?_c('span',[_vm._v("Check In")]):_c('span',[_vm._v("Register")])]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hideRegisterModal}},[_vm._v(" Cancel ")])],1)]}}],null,false,3597141840)})],1):_vm._e(),(_vm.orderStatus === 'complete')?_c('div',{attrs:{"id":"modal-thankyou","centered":"","title":_vm.event.title,"size":"lg","ok-title":"Close","ok-variant":"primary","ok-only":""}},[_c('h4',[_vm._v("Thank you registering for the "+_vm._s(_vm.event.title)+".")]),_c('br'),(_vm.event.meta && _vm.event.meta.website)?_c('h5',[_vm._v("For more information about the "+_vm._s(_vm.event.title)+", please visit "),_c('a',{attrs:{"href":_vm.event.meta.website,"target":"_blank"}},[_vm._v(_vm._s(_vm.event.meta.website))])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }