<template>
    <b-container fluid class="py-5">
        <div v-if="orderStatus === ''">
            <h2>Register</h2>
            <p class="text-muted">Fields marked with an * are required.</p>
            <b-overlay
                variant="primary"
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
            >
                <validation-observer ref="registerForm" #default="{ invalid }">
                    <b-form class="auth-login-form mt-2" @submit.prevent="register">
                        <b-row>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-title" class="font-weight-bold">Title *</label>
                                    <validation-provider #default="{ errors }" name="Title" vid="title" rules="required">
                                        <b-form-select id="register-title" v-model="booking.title" label="title" :options="[
                                            { value: 'Mx', text: 'Mx' },
                                            { value: 'Ms', text: 'Ms' },
                                            { value: 'Mr', text: 'Mr' },
                                            { value: 'Dr', text: 'Dr' },
                                            { value: 'Prof', text: 'Prof' },
                                        ]" name="register-title">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select an
                                                    option</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-firstname" class="font-weight-bold">First Name *</label>
                                    <validation-provider #default="{ errors }" name="First Name" vid="firstname"
                                        rules="required">
                                        <b-form-input id="register-firstname" v-model="booking.firstname"
                                            :state="errors.length > 0 ? false : null" name="register-firstname"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group label="Middle Name" label-for="register-middlename">
                                    <validation-provider #default="{ errors }" name="Middle Name" vid="middlename" rules="">
                                        <b-form-input id="register-middlename" v-model="booking.middlename"
                                            :state="errors.length > 0 ? false : null" name="register-middlename"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-lastname" class="font-weight-bold">Last Name *</label>
                                    <validation-provider #default="{ errors }" name="Last Name" vid="lastname"
                                        rules="required">
                                        <b-form-input id="register-lastname" v-model="booking.lastname"
                                            :state="errors.length > 0 ? false : null" name="register-lastname"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" lg="4">
                                <!-- email -->
                                <b-form-group>
                                    <label label-for="register-email" class="font-weight-bold">Email *</label>
                                    <validation-provider #default="{ errors }" name="Email" vid="email"
                                        rules="required|email">
                                        <b-form-input id="register-email" v-model="booking.email"
                                            :state="errors.length > 0 ? false : null" name="register-email"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="4">
                                <b-form-group>
                                    <label label-for="register-country" class="font-weight-bold">Country *</label>
                                    <validation-provider #default="{ errors }" name="Country" rules="required">
                                        <b-form-select v-model="booking.country" :options="countryOptions"
                                            text-field="label" name="register-country"
                                            :state="errors.length > 0 ? false : null" @change="onCountryChange"
                                            placeholder="">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select an
                                                    option</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="4">
                                <b-form-group>
                                    <label label-for="register-mobile" class="font-weight-bold">Mobile *</label>
                                    <validation-provider #default="{ errors }" name="Mobile" vid="tel"
                                        rules="required|mobile">
                                        <b-form-input id="register-mobile" v-model="booking.mobile"
                                            :state="errors.length > 0 ? false : null" name="register-mobile" placeholder=""
                                            @change="onMobileNumberChange" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group>
                                    <label label-for="register-registration-type" class="font-weight-bold">Registration Type
                                        *</label>
                                    <validation-provider #default="{ errors }" name="Registration Type" rules="required">
                                        <b-form-select v-model="booking.registrationType" :options="tickets"
                                            text-field="label" name="register-registration-type"
                                            :state="errors.length > 0 ? false : null" placeholder="" @change="recalculate">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select an
                                                    option</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group label="Organization" label-for="register-organization">
                                    <validation-provider #default="{ errors }" name="Organization" vid="organization"
                                        rules="">
                                        <b-form-input id="register-organization" v-model="booking.organization"
                                            :state="errors.length > 0 ? false : null" name="register-organization"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group label="Job Title" label-for="register-designation">
                                    <validation-provider #default="{ errors }" name="Job Title" vid="" rules="">
                                        <b-form-input id="register-designation" v-model="booking.designation"
                                            :state="errors.length > 0 ? false : null" name="register-designation"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Billing Address" label-for="register-billing-address">
                                    <validation-provider #default="{ errors }" name="Address1" vid="address" rules="">
                                        <b-form-input id="register-billing-address" v-model="booking.address1"
                                            :state="errors.length > 0 ? false : null" name="register-billing-address"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" lg="3">
                                <b-form-group label="City" label-for="register-city">
                                    <validation-provider #default="{ errors }" name="City" vid="city" rules="">
                                        <b-form-input id="register-city" v-model="booking.city"
                                            :state="errors.length > 0 ? false : null" name="register-city" placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" v-if="stateRequired">
                                <b-form-group>
                                    <label label-for="register-state" class="font-weight-bold">State *</label>
                                    <validation-provider #default="{ errors }" name="State" vid="" rules="required">
                                        <b-form-select v-model="booking.state" :options="stateOptions" text-field="label"
                                            name="register-state" :state="errors.length > 0 ? false : null" placeholder="">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select an
                                                    option</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" v-if="!stateRequired">
                                <b-form-group>
                                    <label label-for="register-state">State</label>
                                    <validation-provider #default="{ errors }" name="State" vid="" rules="">
                                        <b-form-input v-model="booking.state" name="register-state"
                                            :state="errors.length > 0 ? false : null" placeholder=""></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-postal-code">Postal Code</label>
                                    <validation-provider #default="{ errors }" name="Postal Code" vid="" rules="">
                                        <b-form-input v-model="booking.postalcode" name="register-postal-code"
                                            :state="errors.length > 0 ? false : null" placeholder=""></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-country" class="font-weight-bold">Country *</label>
                                    <validation-provider #default="{ errors }" name="Country" vid="" rules="required">
                                        <b-form-input v-model="booking.country" :disabled="true" name="register-country"
                                            :state="errors.length > 0 ? false : null" placeholder=""></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group>
                                    <label label-for="register-payment-mode" class="font-weight-bold">Payment Mode *</label>
                                    <validation-provider #default="{ errors }" name="PaymentMode" rules="required">
                                        <b-form-select v-model="booking.paymentMode" name="register-payment-mode"
                                            :state="errors.length > 0 ? false : null" placeholder="">
                                            <b-form-select-option value="online">Online</b-form-select-option>
                                            <b-form-select-option value="offline">Bank Transfer /
                                                Draft</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group>
                                    <label label-for="register-discount">Discount Code</label>
                                    <validation-provider  #default="{ errors }" name="Discount Code" rules="">
                                        <b-form-input type="text" v-model="booking.discountCode" name="register-discount-code"
                                            :state="errors.length > 0 ? false : null" placeholder=""
                                            debounce="500"
                                            :formatter="discountCodeFormatter"
                                            @change="onDiscountCodeChange">
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="mb-1 py-1 bg-light-primary  rounded-lg">
                            <b-col cols="4" class="d-flex flex-column">
                                <span>Registration</span>
                                <span v-if="cart && cart.cartAmount" class="font-weight-bold">{{ Intl.NumberFormat('en-IN',
                                    {
                                        style: 'currency',
                                    currency: cart.cartCurrency}).format(cart.cartAmount) }}</span>
                            </b-col>
                            <b-col cols="4" class="d-flex flex-column align-items-center">
                                <span>GST</span>
                                <span v-if="cart && cart.taxAmount" class="font-weight-bold">{{ Intl.NumberFormat('en-IN',
                                    {
                                        style: 'currency',
                                    currency: cart.cartCurrency}).format(cart.taxAmount) }}</span>
                            </b-col>
                            <b-col cols="4" class="d-flex flex-column align-items-end">
                                <span>Total</span>
                                <span v-if="cart && cart.cartTotal" class="font-weight-bold">{{ Intl.NumberFormat('en-IN',
                                    {
                                        style: 'currency',
                                    currency: cart.cartCurrency}).format(cart.cartTotal) }}</span>
                            </b-col>
                        </b-row>

                        <b-row v-if="booking.country === 'IN'">
                            <b-col cols="12">
                                <b-checkbox v-model="booking.isGstInvoice" name="register-is-gst-invoice">
                                    Do you require a GST invoice?
                                </b-checkbox>
                            </b-col>
                        </b-row>

                        <b-row v-if="booking.isGstInvoice">
                            <b-col cols="12">
                                <b-form-group>
                                    <label label-for="register-gst-number" class="font-weight-bold">GST Number *</label>
                                    <validation-provider #default="{ errors }" name="GST Number" rules="required">
                                        <b-form-input v-model="booking.gstNumber" name="register-gst-number"
                                            :state="errors.length > 0 ? false : null" placeholder="">
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="booking.isGstInvoice">
                            <b-col cols="12">
                                <b-form-group>
                                    <label label-for="register-gst-company-name" class="font-weight-bold">GST Company Name *
                                        <span class="small>">(as on GST Certificate)</span></label>
                                    <validation-provider #default="{ errors }" name="GST Company Name" rules="required">
                                        <b-form-input v-model="booking.gstCompany" name="register-gst-company-name"
                                            :state="errors.length > 0 ? false : null" placeholder="">
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="booking.isGstInvoice">
                            <b-col cols="12">
                                <b-form-group>
                                    <label label-for="register-gst-address" class="font-weight-bold">GST Address *</label>
                                    <validation-provider #default="{ errors }" name="GST Address" rules="required">
                                        <b-form-input v-model="booking.gstAddress" name="register-gst-address"
                                            :state="errors.length > 0 ? false : null" placeholder="">
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="booking.isGstInvoice">
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-gst-city" class="font-weight-bold">City *</label>
                                    <validation-provider #default="{ errors }" name="GST City" vid="city" rules="required">
                                        <b-form-input id="register-gst-city" v-model="booking.gstCity"
                                            :state="errors.length > 0 ? false : null" name="register-gst-city"
                                            placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-gst-state" class="font-weight-bold">State *</label>
                                    <validation-provider #default="{ errors }" name="State" vid="" rules="required">
                                        <b-form-select v-model="booking.gstState" :options="stateOptions" text-field="label"
                                            name="register-gst-state" :state="errors.length > 0 ? false : null"
                                            placeholder="">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select an
                                                    option</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-gst-postal-code" class="font-weight-bold">Postal Code
                                        *</label>
                                    <validation-provider #default="{ errors }" name="Postal Code" vid="" rules="required">
                                        <b-form-input v-model="booking.gstPostalcode" name="register-gst-postal-code"
                                            :state="errors.length > 0 ? false : null" placeholder=""></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <label label-for="register-gst-country" class="font-weight-bold">Country *</label>
                                    <validation-provider #default="{ errors }" name="GST Country" vid="" rules="required">
                                        <b-form-input v-model="booking.gstCountry" :disabled="true"
                                            name="register-gst-country" :state="errors.length > 0 ? false : null"
                                            placeholder=""></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <validation-provider #default="{ errors }" name="Consent" vid="" rules="required">
                                    <b-checkbox v-model="booking.consent" name="consent"
                                        :state="errors.length > 0 ? false : null">
                                        * I give consent to the organizer and it's fulfillment partners to contact me via
                                        email, sms, whatsapp
                                        or call about this transaction and with updates about the workshop.
                                        I understand that my information shall not be shared with any third party except
                                        with the fulfillment
                                        partners to deliver the workshop.
                                    </b-checkbox>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-button type="submit" variant="primary"
                            :disabled="invalid || !booking.consent || orderStatus === 'complete'" class="float-right">
                            <span v-if="eventMode === 'login'">Check In</span><span v-else>Register</span>
                        </b-button>
                        <b-button type="button" variant="outline-secondary" class="mr-1 float-right" @click="onHide">
                            Cancel
                        </b-button>
                    </b-form>
                </validation-observer>
            </b-overlay>
            <b-modal id="modal-continue-register" centered cancel-disabled static title="" size="lg"
                footer-class="d-none">
                <div v-html="ticketNote"></div>
                <b-button type="button" variant="primary" class="mt-1 float-right" @click="onContinueRegistration">
                    Continue Registration
                </b-button>
                <b-button type="button" variant="outline-secondary" class="mt-1 mr-1 float-right"
                    @click="onCancelRegistration">
                    Cancel
                </b-button>
            </b-modal>
        </div>
        <div v-if="orderStatus === 'processing'">
            <h3>Processing Registration</h3>
            <p>Thank you for registering for {{ this.cart.title }}. We shall confirm your registration upon receipt of
                payment.
            </p>
            <p>We have emailed the registration and offline payment details to you. If you do not receive confirmation
                and
                workshop detail emails from us, please do check your spam/junk email folder.</p>
        </div>
        <div v-if="orderStatus === 'aborted'">
            <h3>Registration Incomplete</h3>
            <p class="font-weight-bold">Thank you for your interest in {{ this.cart.title }}. Unfortunately your payment
                was not
                successful.</p>
            <p v-if="event.organizer[0].contactEmail">If you have any questions or need help completing your
                registration,
                please email <a href="mailto:event.organizer[0].contactEmail" target="_blank">{{
                    event.organizer[0].contactEmail
                    }}</a></p>
        </div>
        <div v-if="orderStatus === 'complete'">
            <h3>Registration Successful</h3>
            <p>Thank you for registering for {{ this.cart.title }}. We have emailed the registration details to you. If
                you do
                not receive confirmation and workshop detail emails from us, please do check your spam/junk email
                folder.</p>
        </div>
        <b-button v-if="orderStatus === 'aborted'" type="button" variant="success" class="ml-1 mt-1 float-right"
            @click="resetOrderStatus">
            Retry Payment
        </b-button>
        <b-button v-if="orderStatus !== ''" type="button" variant="primary" class="mt-1 float-right" @click="onHide">
            Close
        </b-button>
    </b-container>
</template>


<script>
import {
    BContainer,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BForm,
    BButton,
    BCollapse,
    BOverlay,
} from 'bootstrap-vue';
import { required, email } from "@validations";
import { AsYouType, isValidPhoneNumber, parseIncompletePhoneNumber, parsePhoneNumber } from "libphonenumber-js/mobile"
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import countries from '@/assets/data/countries.json'
import api from '@/store/api/api';
import apiConfig from '@/store/api/apiConfig';
import store from "@/store/index";

const MobileNumberValidator = {
    getMessage: field => `The ${field} must be a valid mobile number`,
    validate: value => {
        const parsedPhoneNumber = parseIncompletePhoneNumber(value);
        // console.log('parsedPhoneNumber', parsedPhoneNumber, value);
        if (isValidPhoneNumber(parsedPhoneNumber)) {
            return true;
        }
        return false;
    }
};

extend("mobile", MobileNumberValidator);

export default {
    name: 'RegistrationFormDetailed',
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCollapse,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BForm,
        BButton,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            booking: {
                title: "",
                firstname: "",
                middlename: "",
                lastname: "",
                registrationType: "",
                designation: "",
                organization: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                postalcode: "",
                country: "",
                hsnCode: "",
                hsnRate: 0.0,
                paymentMode: 'online',
                amount: 0.0,
                discountCode: '',
                discountPercent: 0,
                notes: '',
                isGstInvoice: false,
                gstCountry: "India",
                gstCompany: "",
                gstNumber: "",
                gstAddress: "",
                gstCity: "",
                gstState: "",
                gstPostalcode: "",
                consent: false,
                email: "",
                mobile: ""
            },
            ticketNote: '',
            orderStatus: '',
            cart: {},
            stateRequired: false,
            location: {},
            // validation rules
            required,
            email,
            showLoading: false,
        };
    },
    computed: {
        countryOptions() {
            return Object.values(countries)
        },
        stateOptions() {
            return [
                { "value": "01-JAMMU AND KASHMIR", "label": "JAMMU AND KASHMIR" },
                { "value": "02-HIMACHAL PRADESH", "label": "HIMACHAL PRADESH" },
                { "value": "03-PUNJAB", "label": "PUNJAB" },
                { "value": "04-CHANDIGARH", "label": "CHANDIGARH" },
                { "value": "05-UTTARAKHAND", "label": "UTTARAKHAND" },
                { "value": "06-HARYANA", "label": "HARYANA" },
                { "value": "07-DELHI", "label": "DELHI" },
                { "value": "08-RAJASTHAN", "label": "RAJASTHAN" },
                { "value": "09-UTTAR PRADESH", "label": "UTTAR PRADESH" },
                { "value": "10-BIHAR", "label": "BIHAR" },
                { "value": "11-SIKKIM", "label": "SIKKIM" },
                { "value": "12-ARUNACHAL PRADESH", "label": "ARUNACHAL PRADESH" },
                { "value": "13-NAGALAND", "label": "NAGALAND" },
                { "value": "14-MANIPUR", "label": "MANIPUR" },
                { "value": "15-MIZORAM", "label": "MIZORAM" },
                { "value": "16-TRIPURA", "label": "TRIPURA" },
                { "value": "17-MEGHLAYA", "label": "MEGHLAYA" },
                { "value": "18-ASSAM", "label": "ASSAM" },
                { "value": "19-WEST BENGAL", "label": "WEST BENGAL" },
                { "value": "20-JHARKHAND", "label": "JHARKHAND" },
                { "value": "21-ODISHA", "label": "ODISHA" },
                { "value": "22-CHATTISGARH", "label": "CHATTISGARH" },
                { "value": "23-MADHYA PRADESH", "label": "MADHYA PRADESH" },
                { "value": "24-GUJARAT", "label": "GUJARAT" },
                { "value": "25-DAMAN AND DIU", "label": "DAMAN AND DIU" },
                { "value": "26-DADRA AND NAGAR HAVELI", "label": "DADRA AND NAGAR HAVELI" },
                { "value": "27-MAHARASHTRA", "label": "MAHARASHTRA" },
                { "value": "29-KARNATAKA", "label": "KARNATAKA" },
                { "value": "30-GOA", "label": "GOA" },
                { "value": "31-LAKSHWADEEP", "label": "LAKSHWADEEP" },
                { "value": "32-KERALA", "label": "KERALA" },
                { "value": "33-TAMIL NADU", "label": "TAMIL NADU" },
                { "value": "34-PUDUCHERRY", "label": "PUDUCHERRY" },
                { "value": "35-ANDAMAN AND NICOBAR ISLANDS", "label": "ANDAMAN AND NICOBAR ISLANDS" },
                { "value": "36-TELANGANA", "label": "TELANGANA" },
                { "value": "37-ANDHRA PRADESH", "label": "ANDHRA PRADESH" },
            ]
        },
        event() {
            return store.state.event.event;
        },
        tickets() {
            const pricing = store.state.event.event.priceOptions
            let priceOptions = []
            if (pricing) {
                for (const price of pricing) {
                    if (this.$date(price.validFrom).isBefore(this.$date()) && this.$date(price.validTill).isAfter(this.$date())) {
                        priceOptions.push({ value: price.label, label: price.label, price })
                    }
                }
            }
            return priceOptions
        },
    },
    methods: {
        onCountryChange(value) {
            // console.log('country code changed', value)
            if (value === 'IN') {
                this.stateRequired = true
            } else {
                this.stateRequired = false
            }
            this.booking.mobile = countries[value].dialCode
            this.recalculate()
        },
        discountCodeFormatter(value) {
            return value.toUpperCase()
        },
        async onDiscountCodeChange(value) {
            // console.log('discount code changed', value)
            try {
                const response = await api.get(`/coupons/validate/${value}`);
                if (response && response.status === 200) {
                    this.booking.discountPercent = response.data.discount
                    this.$refs.registerForm.setErrors({ 'booking.discountCode': [] })

                }
                // console.log('discount code response', response);
            } catch (error) {
                console.error(error)
                this.booking.discountPercent = 0
                this.$refs.registerForm.setErrors({ 'booking.discountCode': [error?.response?.data?.error?.message || 'Invalid code'] })
            }
            this.recalculate()
        },
        onMobileNumberChange(value) {
            // console.log('onMobileNumberChange', value)
            const mobileNumber = parsePhoneNumber(value, this.booking.country);
            // console.log('mobileNumber', mobileNumber)
            if (mobileNumber) {
                const formattedMobileNumber = mobileNumber.formatInternational();
                this.booking.mobile = new AsYouType(this.booking.country).input(formattedMobileNumber);
            }
        },
        recalculate() {
            // console.log('recalculating');
            let cart = this.cart;

            let booking = this.booking;
            if (!cart.slug) {
                // initialize the cart
                cart = {
                    id: this.event.id,
                    slug: this.event.slug,
                    title: this.event.title,
                    description: '',
                    image: this.event.banner,
                    priceInternational: this.event.priceInternational,
                    priceCurrency: this.event.currency,
                    price: this.event.price,
                    cartCurrency: this.event.currency,
                    cartAmount: this.event.price,
                    hsnRate: this.event.hsnRate,
                    hsnCode: this.event.hsnCode,
                    taxIncluded: false,
                    orderId: ""
                }
            }
            cart.cartAmount = 0;
            cart.cartCurrency = '';
            cart.price = 0;
            cart.priceCurrency = '';
            cart.taxAmount = 0;
            cart.cartTotal = 0;
            // console.log('cart', cart);
            // console.log('booking', booking);
            this.ticketNote = ''
            for (const price of this.event.priceOptions) {
                if (booking.registrationType && booking.registrationType.length > 0) {
                    if (price.label === booking.registrationType) {
                        if (price.note) {
                            this.ticketNote = price.note
                        }
                        for (const amount of price.amounts) {
                            if (booking.country === "IN" && amount.country === 'IN') {
                                cart.price = amount.amount;
                                cart.priceCurrency = amount.currency;
                                cart.cartAmount = amount.amount;
                                cart.cartCurrency = amount.currency;
                                cart.taxAmount = amount.amount * cart.hsnRate / 100;
                                cart.cartTotal = cart.cartAmount + cart.taxAmount;
                                cart.taxIncluded = amount.taxIncluded;
                            } else if (booking.country !== "IN" && amount.country === '') {
                                cart.price = amount.amount;
                                cart.priceCurrency = amount.currency;
                                cart.cartAmount = amount.amount;
                                cart.cartCurrency = amount.currency;
                                cart.taxAmount = amount.amount * cart.hsnRate / 100;
                                cart.cartTotal = cart.cartAmount + cart.taxAmount;
                                cart.taxIncluded = amount.taxIncluded;
                            }
                        }
                        break;
                    }
                } else {
                    for (const amount of price.amounts) {
                        if (booking.country === "IN" && amount.country === 'IN') {
                            cart.price = amount.amount;
                            cart.priceCurrency = amount.currency;
                            cart.cartAmount = amount.amount;
                            cart.cartCurrency = amount.currency;
                            cart.taxAmount = amount.amount * cart.hsnRate / 100;
                            cart.cartTotal = cart.cartAmount + cart.taxAmount;
                            cart.taxIncluded = amount.taxIncluded;
                            this.booking.registrationType = price.label
                        } else if (booking.country !== "IN" && amount.country === '') {
                            cart.price = amount.amount;
                            cart.priceCurrency = amount.currency;
                            cart.cartAmount = amount.amount;
                            cart.cartCurrency = amount.currency;
                            cart.taxAmount = amount.amount * cart.hsnRate / 100;
                            cart.cartTotal = cart.cartAmount + cart.taxAmount;
                            cart.taxIncluded = amount.taxIncluded;
                            this.booking.registrationType = price.label
                        }
                    }
                    break;
                }
            }

            if (booking.discountPercent) {
                cart.discountPercent = booking.discountPercent;
                cart.discountAmount = Math.round(cart.cartAmount * (booking.discountPercent / 100));
                cart.cartAmount = Math.round(cart.cartAmount - cart.discountAmount);
            }
            if (cart.taxIncluded) {
                cart.taxAmount = 0;
                cart.cartTotal = cart.cartAmount;
            } else {
                cart.taxAmount = cart.cartAmount * cart.hsnRate / 100;
                cart.cartTotal = cart.cartAmount + cart.taxAmount;
            }

            this.booking.amount = cart.cartTotal
            this.booking.currency = cart.cartCurrency
            this.booking.hsnCode = cart.hsnCode
            this.booking.hsnRate = cart.hsnRate
            // console.log('cart', cart);
            // console.log('booking', booking);
            this.cart = cart
        },
        async getAbandonedCart() {
            this.showLoading = true;
            const response = await api.get(`/orders/abandoned/${this.orderId}`);

            const registration = response.data;
            // console.log('existing registration', registration);
            if (registration && registration.meta && (registration.meta.formData || registration.meta.customer)) {
                let _booking = registration.meta.formData ? Object.assign({}, this.booking, registration.meta.formData) : Object.assign({}, this.booking, registration.meta.customer);
                _booking.paymentMode = "online";
                _booking.consent = false;
                this.booking = _booking
                if (registration.status === 'complete') {
                    this.orderStatus = 'complete'
                }
                let event = registration.orderable;
                let cart = {
                    id: event.id,
                    slug: event.slug,
                    title: `${event.title}`,
                    variant: _booking.registrationType,
                    description: '',
                    image: event.banner,
                    priceInternational: event.priceInternational,
                    priceCurrency: registration.items[0].currency,
                    price: registration.items[0].unitPrice,
                    cartCurrency: registration.items[0].currency,
                    cartAmount: registration.items[0].unitPrice,
                    hsnRate: event.hsnRate,
                    hsnSac: event.hsnCode,
                    taxIncluded: event.taxIncluded,
                    tax_percent: event.hsnRate,
                    tax_amount: event.taxIncluded ? 0 : (registration.items[0].unitPrice * event.hsnRate / 100),
                    orderId: registration.documentNumber,
                    meta: {}
                }
                if (event.priceOptions && event.priceOptions.length > 0) {
                    cart.priceOptions = event.priceOptions;
                }
                this.cart = cart
                this.recalculate()
            }
            this.showLoading = false;
        },
        async getLocation() {
            let location = {}
            try {
                // const response = await api.get('https://geolocation-db.com/json/');
                // // console.log('response', response);
                // location = response.data;
                const response = await api.get('https://api.hummz.net/v2/countries/ip2location');
                location = response.data;
            } catch (error) {
                // report error
                console.error('Location error', error);
            }
            return location
        },
        // onHide() {
        //     this.$emit('hideRegisterModal');
        // },
        register() {
            // console.log('register', this.booking)
            // console.log('refs', this.$refs)
            if (!this.booking.consent) {
                this.$refs.registerForm.setErrors({ consent: ['Please give consent to proceed'] })
                return;
            }
            if (this.ticketNote) {
                this.$bvModal.show('modal-continue-register')
            } else {
                this.createOrder();
            }
        },
        onContinueRegistration() {
            this.createOrder();
            this.$bvModal.hide('modal-continue-register')
        },
        onCancelRegistration() {
            this.$bvModal.hide('modal-continue-register')
        },
        resetOrderStatus() {
            this.orderStatus = ''
        },
        async createOrder() {
            // if (this.cart && this.cart.orderId) {
                // this.handlePayment(this.cart.orderId, this.cart.orderRef);
            // } else {
                let order = {};

                order.issueDate = (new Date()).toISOString();
                order.type = 'event';
                order.customer = {
                    title: this.booking.title,
                    firstname: this.booking.firstname,
                    middlename: this.booking.middlename,
                    lastname: this.booking.lastname,
                    name: this.booking.title + ' ' + this.booking.firstname + ' ' + this.booking.middlename + ' ' + this.booking.lastname,
                    email: this.booking.email,
                    mobile: this.booking.mobile,
                    designation: this.booking.designation,
                    organization: this.booking.organization,
                    address1: this.booking.address1,
                    address2: this.booking.address2,
                    city: this.booking.city,
                    state: this.booking.state,
                    postalcode: this.booking.postalcode,
                    country: this.booking.country
                };
                order.items = [
                    {
                        id: this.cart.id,
                        sku: this.cart.slug,
                        name: this.cart.title,
                        variant: this.booking.registrationType,
                        hsnSac: this.cart.hsnCode,
                        quantity: 1,
                        currency: this.cart.cartCurrency,
                        unitPrice: this.cart.cartAmount,
                        unit: 'no',
                        discount_percent: this.cart.discountPercent || 0,
                        discount_amount: this.cart.discountAmount || 0,
                        tax_percent: this.cart.hsnRate,
                        tax_amount: this.cart.taxIncluded ? 0 : (this.cart.cartAmount * this.cart.hsnRate / 100),
                        meta: {}
                    }
                ];
                order.discountCode = this.cart.discountCode || '';
                order.totalQuantity = 1;
                order.currency = this.cart.cartCurrency;
                order.totalDiscountAmount = this.cart.discountAmount || 0;
                order.totalTaxAmount = this.cart.taxIncluded ? 0 : (this.cart.cartAmount * this.cart.hsnRate / 100);
                order.totalAmount = this.cart.cartAmount;
                order.totalNetAmount = (order.totalAmount) + order.totalTaxAmount;
                order.totalTaxableAmount = order.totalAmount;
                order.isSubscription = false;
                order.orderableId = this.cart.id;
                order.orderableType = "event";
                order.subscriptionFrequency = '';
                order.subscriptionCount = 0;
                order.gst = {
                    gstNumber: this.booking.gstNumber,
                    gstCompany: this.booking.gstCompany,
                    gstAddress: this.booking.gstAddress,
                    gstCity: this.booking.gstCity,
                    gstState: this.booking.gstState,
                    gstPostalcode: this.booking.gstPostalcode,
                    gstCountry: this.booking.gstCountry
                }
                order.meta = {
                    metadata: {
                        "timezone": (new window.Intl.DateTimeFormat().resolvedOptions().timeZone),
                        "offset": (new Date()).getTimezoneOffset(),
                        "referrer": document.referrer,
                        "browserName": navigator.appName,
                        "userAgent": navigator.userAgent,
                        "pathName": window.location.href || 'https://www.hummz.events',
                        "onLine": navigator.onLine,
                        "ipaddress": this.location.query || '',
                        "location": this.location,
                    },
                    formSlug: window.location.host,
                    formData: this.booking
                }

                if (this.booking.paymentMode === 'offline') {
                    order.status = 'processing';
                }

                try {
                    const response = await api.post('/orders/payment', order)

                    // console.log('resolving successfully', response);
                    let orderId = response.data.documentNumber;
                    let orderRef = response.data.orderReference[0].reference;
                    let cart = this.cart;
                    cart.orderId = orderId;
                    cart.orderRef = orderRef;
                    this.cart = cart;
                    if (typeof window !== 'undefined') {
                        localStorage.setItem('cart', JSON.stringify(cart));
                    }
                    if (this.booking.paymentMode === 'online') {
                        // console.log('payment mode online');
                        this.handlePayment(orderId, orderRef);
                    } else {
                        this.paymentSuccessful = false
                        this.orderStatus = 'processing'
                        this.booking = {}
                    }
                    // this.handlePayment(orderId, orderRef);
                } catch (error) {
                    if (apiConfig.isProduction) {
                        // Sentry.captureException(error)
                    } else {
                        console.error(error);
                    }
                }
            // }
        },
        async handlePayment(orderId, orderRef) {
            const organizer = Array.isArray(this.event.organizer) ? this.event.organizer[0] : this.event.organizer;
            const __this = this
            // console.log('handlePayment', orderId, orderRef, this.cart);
            let options = {
                key: apiConfig.isProduction ? "rzp_live_DaBZwF5h0IFzfR" : "rzp_test_EfnGW77z6PMPCo",
                amount: this.cart.cartTotal * 100,
                currency: this.cart.cartCurrency,
                order_id: orderRef,
                name: organizer.name,
                description: this.cart.title,
                image: organizer.logo,
                handler: async function (response) {
                    // formData.meta.paymentId = response.razorpay_payment_id;
                    // console.log('Payment received', response);
                    if (orderId && orderId.length) {
                        try {
                            const responseJson = await api.patch(`/orders/payment/${orderId}`, { paymentDetail: response })
                            // console.log('order update response', responseJson)
                        } catch (error) {
                            if (apiConfig.isProduction) {
                                // Sentry.captureException(error)
                            } else {
                                console.error(error);
                            }
                        }
                        __this.paymentSuccessful = true
                        __this.orderStatus = 'complete'
                        __this.booking = {}
                    }
                },
                modal: {
                    ondismiss: async function () {
                        // console.log('Checkout form closed');
                        __this.paymentSuccessful = false
                        __this.orderStatus = 'aborted'
                        try {
                            const responseJson = await api.patch(`/orders/payment/${orderId}`, { paymentDetail: {}, status: 'aborted' })
                            // console.log('order update response', responseJson)
                        } catch (error) {
                            if (apiConfig.isProduction) {
                                // Sentry.captureException(error)
                            } else {
                                console.error(error);
                            }
                        }
                    }
                },
                prefill: {
                    name: this.booking.firstname + ' ' + this.booking.middlename + ' ' + this.booking.lastname,
                    email: this.booking.email,
                    contact: this.mobile,
                },
                notes: {
                    slug: this.cart.slug,
                    type: "event",
                    name: this.booking.firstname + ' ' + this.booking.middlename + ' ' + this.booking.lastname,
                    email: this.booking.email,
                    phone: this.booking.mobile,
                    country: this.country
                },
                theme: {
                    color: "#c4aea2"
                }
            };
            // console.log('Options', options);
            // console.log('RAZORPAY', window.Razorpay)
            let rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    },
    async mounted() {
        // console.log('mounted', this.$route);
        const location = await this.getLocation()
        this.location = location
        this.booking.country = location.countryCode
        this.onCountryChange(location.countryCode)
        // console.log('country', this.booking.country, location) 

        // if an order id is passed, get the abandoned order and populate the form
        if (this.orderId) {
            this.getAbandonedCart()
        }

        // Add razorpay script
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    },
    props: {
        eventMode: {
            type: String,
            required: true,
        },
        eventSlug: {
            type: String,
            required: true,
        },
        onHide: {
            type: Function,
            required: true,
        },
        orderId: {
            type: String,
            required: false,
            default: null
        },
    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>